html {
  -ms-scroll-padding-top: 0; /* IE/Edge */
  -webkit-scroll-padding-tope: 0; /* Safari */
  scroll-behavior: initial;
  -ms-scroll-behavior: initial;
  -webkit-scroll-behavior: initial;
}

body {
  counter-reset: my-sec-counter;
  -ms-scroll-padding-top: 0; /* IE/Edge */
  -webkit-scroll-padding-tope: 0; /* Safari */
}

.--sh-sm {
  display: none;
}

.wv-dropdown.show .dropdown-content {
  display: block;
  transition: ease 0.3s;
}

.wv-dropdown.show .dropbtn {
  border-color: #e65100;
  color: #e65100;
}

.wv-dropdown .link.active::after {
  transform: rotate(-180deg);
  transition: ease 0.3s;
}
.wv-dropdown .link {
  width: 100%;
  text-align: left;
  background: none;
  outline: 0;
  border: 2px solid #ff7900;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  color: #ff7900;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wv-dropdown .link::after {
  content: "";
  background-image: url("../img/arrow-down.svg");
  width: 12px;
  height: 12px;
  background-size: cover;
  display: inline-block;
  margin-top: 4px;
  transition: ease 0.3s;
}
.accordion-item.submenu.dropdown-content li {
  list-style: none;
  padding-top: 10px;
}

.wv-dropdown .dropdown-content {
  background: #f8f9fa 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #9e9e9e40;
  transition: ease 0.3s;
}

.wv-dropdown .dropdown-content a {
  display: block;
  color: #5f6367;
  padding: 8px;
  border-radius: 2px;
  font-size: 18px;
  cursor: pointer;
  line-height: 24px;
  margin-bottom: 8px;
  padding-bottom: 0px;
}
.wv-dropdown .dropdown-content a:last-child {
  padding-bottom: 10px;
}

.wv-dropdown .dropdown-content a:hover {
  text-decoration: none;
  background-color: #ece9e9;
}

.wv-border-botton-footer {
  border-color: #444;
}
.--footer-border {
  border-color: #444;
  margin-left: 0;
  margin-right: 0;
}

.wv-brand-logo {
  font-family: "Roboto-Regular";
  margin: 0;
  font-size: 18px;
  color: #adadad;
  letter-spacing: 0.5px;
  font-weight: 800;
}
.wv-brand-extra {
  position: absolute;
  top: 6px;
  padding-left: 35px;
}

.wv-header a.wv-pt0 {
  padding-top: 0;
}

.wv-brand-name {
  font-size: 26px;
  font-family: "Roboto-Regular";
  font-weight: 800;
  letter-spacing: 0.5px;
  color: #314af2;
}

.wv-inner-banner {
  min-height: auto;
  align-items: center;
  display: flex;
  padding: 40px;
}
.wv-banner-text-large {
  font-size: 50px;
  line-height: 40px;
  font-family: "Roboto-Regular";
  font-weight: 800;
  margin-bottom: 30px;
}
.wv-banner-text-medium {
  font-size: 30px;
  line-height: 42px;
  font-family: "Roboto-Regular";
  font-weight: bolder;
  letter-spacing: -0.6px;
  width: 75%;
  margin: 0;
}

.wv-banner-text-small {
  font-size: 18px;
  line-height: 32px;
  font-family: "Roboto-Medium";
  margin-bottom: 0px;
}
.wv-landing-page-1 {
  background: #fff8e1;
  position: relative;
  z-index: 4;
}

.--green-bg {
  background-color: #e8efff !important;
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.wv-landing-page-1.-cost-reduction {
  background-color: #e3f2fd;
  border-color: #e3f2fd;
}

.-light-yellow {
  background-color: #fff8e1 !important;
}

.-pure-yellow {
  background-color: #fff9c4 !important;
}

.-innovative {
  background-color: #e8eeff !important;
}

.-ai-impact {
  background: #fff3e0 !important;
}

.-self-service {
  background: #f9fbe7 !important;
}

.-g-vs-t-aut {
  background: #e8f5e9;
}
.max-width-255 {
  max-width: 255px;
}

.wv-sidenav {
  position: relative;
  top: 20px;
  transition: ease 0.3s;
}
.--dark-footer ul li a:hover {
  color: #fff !important;
  text-decoration: none;
}
.--dark-footer .wv-social-link img:hover {
  filter: brightness(0.5);
}

.wv-landing-page-1 .container,
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.wv-box-content {
  color: initial;
  padding: 50px;
  flex: 1;
  margin: 0 15px;
  max-width: 320px;
  transition: ease 0.3s;
}

.wv-box-content:hover,
.wv-box-content:focus,
.wv-box-content:active {
  text-decoration: none;
  color: initial;
}

.wv-banner-box-content {
  position: relative;
  top: -5%;
  z-index: 8;
}
/* 
.wv-box-content:hover {
  background-color: #fff59f;
  transition: ease 0.3s;
} */

.wv-box-content.--green-bg {
  border: 1px solid#e8efff !important;
  background-color: #e8efff !important;
}

.wv-box-content.--green-bg:hover {
  background-color: #a7ffeb !important;
}

.wv-box-content.--innovative-tech {
  background-color: #e8eeff;
  border-color: #e8eeff;
}

.wv-box-content.--innovative-tech:hover {
  background-color: #e1d4f4;
  border-color: #e1d4f4;
}

.wv-box-content.-light-yellow {
  background-color: #fff8e1;
  border-color: #fff8e1;
}

.wv-box-content.-light-yellow:hover {
  background-color: #ffecb3;
  border-color: #ffecb3;
}

.-cost-reduction {
  background-color: #e3f2fd;
  border-color: #e3f2fd;
}

.wv-box-content.-cost-reduction:hover {
  background-color: #d0ebff;
}

.wv-box-content.-impact-ai {
  background-color: #fff3e0;
  border-color: #fff3e0;
}

.wv-box-content.-impact-ai:hover {
  background-color: #ffe0b2;
}

.wv-box-content.-self-service-aut {
  background-color: #f9fbe7;
  border-color: #f9fbe7;
}

.wv-box-content.-self-service-aut:hover {
  background-color: #f0f4c3;
}

.wv-box-content.-g-vs-true-aut {
  background-color: #f4fff5;
  border-color: #f4fff5;
}

.wv-box-content.-g-vs-true-aut:hover {
  background-color: #c8e6c9;
}

.--dark-footer {
  background: #333333;
}

.--dark-footer .container {
  position: relative;
}

.--dark-footer .--quick-link h4 {
  color: #adadad;
}

.--dark-footer .--quick-link ul li a {
  color: #adadad;
}
.--footer-sublink ul li a {
  color: #adadad;
}
.pt-210 {
  padding-top: 0px;
}
.pb-210 {
  padding-bottom: 0px;
  margin-bottom: 40px;
}
.__footer-content {
  height: 420px;
  background-color: #fff9c4;
  text-align: center;
  position: absolute;
  top: -250px;
  right: 0;
  left: 0;
}

.__footer-content.-cost-reduction {
  background-color: #e3f2fd;
}
.__footer-content.-general-vs-true-automation {
  background-color: #e8f5e9;
}

.--bg-body-content {
  height: 602px;
  background-color: rgba(0, 0, 0, 0.7);
  background-size: cover;
  position: relative;
}

.--bg-body-content .max-width-70.cost-color {
  color: #e0f7fa;
  font-size: 40px;
  padding-left: 370px;
  max-width: 80%;
}

.--bg-body-content h4 {
  position: absolute;
  bottom: 15%;
  left: 10%;
  font-size: 50px;
  max-width: 650px;
  line-height: 62px;
  font-weight: bolder;
  color: #fff8e1;
  font-family: "Roboto-Regular";
}

.--bg-body-content h4.true-automation-color {
  color: #c8e6c9;
}

.v-aign-banner-content {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  top: -170px;
}

.pb-200 {
  padding-bottom: 0px;
  width: 100%;
  float: left;
}
.wv-section-mv-p {
  width: 100%;
  float: left;
}

.max-width-35 {
  max-width: 32px;
}

.--bg-body-content .max-width-70 {
  max-width: 70%;
  color: #fff;
}

.landing-feature-style thead tr td {
  border: 1px solid #c8e6c9;
  background-color: #eceff1;
  text-align: center;
  padding: 42px 20px;
}

.landing-feature-style thead tr td:first-child {
  border-right: none;
}

.landing-feature-style tbody {
  border: 1px solid #eceff1;
}

.landing-feature-style tbody tr td:first-child {
  border-right: 1px solid #eceff1;
}
.landing-feature-style tbody tr td {
  vertical-align: text-top;
  padding: 30px;
}

.wv-select-box {
  border: 2px solid #4095e9;
  background-color: transparent;
  color: #4095e9;
  width: 100%;
  display: inline-block;
  border-radius: 1px;
  padding: 10px;
  font-size: 18px;
}

.wv-dropdown .dropdown-content a:hover {
  text-decoration: none;
  background-color: #f5f3f3;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .wv-banner .container {
    text-align: left !important;
    height: inherit;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .wv-banner .wv-lg-heading {
    font-size: 22px;
    line-height: 38px;
    width: inherit;
  }
  .wv-banner-text-medium {
    font-size: 30px;
    line-height: 36px;
  }
  .wv-banner-text-large {
    font-size: 36px;
  }
  .container.text-center .wv-md-heading.mb-1 {
    padding-top: 30px;
  }
  .wv-banner .wv-md-heading {
    font-size: 16px;
    line-height: 36px;
  }
  /* .wv-banner.wv-landing-page-1 {
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  } */
  .--bg-body-content {
    height: 430px;
  }
  .wv-section-mv-p {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    width: 100%;
    float: left;
  }
  .wv-section-mv-m {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }

  .wv-section-mv-p0 {
    padding-top: 0;
  }

  .wv-landing-page-1 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .__footer-content {
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
    align-items: baseline;
  }
  .offset-none {
    margin-left: 5px;
  }
}
